import ImagesLoaded from 'imagesloaded'
import { Swiper, Navigation, Pagination, A11y } from 'swiper'

Swiper.use([Navigation, Pagination, A11y])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const carousels = document.querySelectorAll('[data-imagesCarousel]')

  if (!carousels.length) return

  const carouselsOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    roundLengths: true,
    watchSlidesVisibility: true,
    navigation: {},
    pagination: {
      el: '.ImagesCarousel-pagination',
      type: 'bullets',
      dynamicBullets: true
    },
    a11y: {
      prevSlideMessage: 'Visuel précédent',
      nextSlideMessage: 'Visuel suivant',
    },
    breakpoints: {
      640: {
        spaceBetween: 30
      }
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }
  }

  carousels.forEach(carousel => {
    const carouselContainer = carousel.querySelector('.ImagesCarousel-container')

    carouselsOptions.navigation.nextEl = carousel.querySelector('.ImagesCarousel-button--next')
    carouselsOptions.navigation.prevEl = carousel.querySelector('.ImagesCarousel-button--prev')

    new ImagesLoaded(carousel, () => {
      const carouselInit = new Swiper(carouselContainer, carouselsOptions)

      carouselInit.on('transitionEnd', function () {
        this.slides.forEach(slide => {
          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })

        this.slides[this.activeIndex].focus()
      })
    })
  })
}
